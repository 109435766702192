// extracted by mini-css-extract-plugin
export var buttons = "bp_bP";
export var left = "bp_ck";
export var media = "bp_gs";
export var right = "bp_cR";
export var section = "bp_j";
export var selected = "bp_bg";
export var signature = "bp_gr";
export var testimonial = "bp_gq";
export var text = "bp_bK";
export var textContainer = "bp_bS";
export var video = "bp_fD";