// extracted by mini-css-extract-plugin
export var buttons = "Y_bP";
export var container = "Y_h";
export var image = "Y_k";
export var innerText = "Y_d2";
export var logo = "Y_bn";
export var p = "Y_d0";
export var section = "Y_j";
export var short = "Y_d1";
export var tall = "Y_d3";
export var textContainer = "Y_bS";