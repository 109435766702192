// extracted by mini-css-extract-plugin
export var arrow = "N_9";
export var body = "N_g";
export var circle = "N_dt";
export var customer = "N_ds";
export var feedback = "N_dr";
export var feedbacks = "N_dp";
export var footer = "N_b7";
export var footerText = "N_bV";
export var left = "N_ck";
export var nameLocation = "N_dv";
export var padding = "N_dm";
export var right = "N_cR";
export var section = "N_j";
export var selected = "N_bg";
export var selectors = "N_dn";
export var subtitle = "N_bT";
export var text = "N_bK";
export var title = "N_d";
export var wrapper = "N_dq";