// extracted by mini-css-extract-plugin
export var container = "bn_h";
export var feature = "bn_b3";
export var featureText = "bn_b4";
export var features = "bn_b1";
export var footer = "bn_b7";
export var footerText = "bn_bV";
export var icon = "bn_bz";
export var section = "bn_j";
export var text = "bn_bK";
export var title = "bn_d";
export var waveOne = "bn_q";
export var waveThree = "bn_bX";
export var waveTwo = "bn_n";
export var waves = "bn_bW";