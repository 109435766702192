// extracted by mini-css-extract-plugin
export var buttons = "P_bP";
export var container = "P_h";
export var content = "P_b5";
export var icon = "P_bz";
export var iconContainer = "P_cG";
export var image = "P_k";
export var section = "P_j";
export var selected = "P_bg";
export var tab = "P_dx";
export var tabText = "P_dw";