// extracted by mini-css-extract-plugin
export var appStores = "J_c4";
export var grey = "J_c5";
export var icon = "J_bz";
export var iconGroup = "J_c3";
export var image = "J_k";
export var left = "J_ck";
export var reverse = "J_c2";
export var right = "J_cR";
export var section = "J_j";
export var textSection = "J_c1";