// extracted by mini-css-extract-plugin
export var answer = "br_gJ";
export var arrow = "br_9";
export var body = "br_g";
export var category = "br_bv";
export var contactIcons = "br_gB";
export var current = "br_d6";
export var fade = "br_gF";
export var footer = "br_b7";
export var footerText = "br_bV";
export var icon = "br_bz";
export var iconContainer = "br_cG";
export var issue = "br_gH";
export var issueContainer = "br_gD";
export var issueText = "br_gG";
export var issues = "br_gC";
export var left = "br_ck";
export var padding = "br_dm";
export var right = "br_cR";
export var section = "br_j";
export var selectors = "br_dn";
export var subtitle = "br_bT";
export var title = "br_d";