// extracted by mini-css-extract-plugin
export var active = "bm_bd";
export var categories = "bm_bt";
export var category = "bm_bv";
export var icon = "bm_bz";
export var icons = "bm_cb";
export var image = "bm_k";
export var member = "bm_gn";
export var memberTitle = "bm_gp";
export var name = "bm_d9";
export var placeholder = "bm_bb";
export var section = "bm_j";
export var show = "bm_L";
export var subtitle = "bm_bT";
export var team = "bm_gm";