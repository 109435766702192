// extracted by mini-css-extract-plugin
export var active = "s_bd";
export var activeTargetContainer = "s_b0";
export var container = "s_h";
export var content = "s_b5";
export var contentButton = "s_b6";
export var feature = "s_b3";
export var featureContainer = "s_b2";
export var featureText = "s_b4";
export var features = "s_b1";
export var footer = "s_b7";
export var footerText = "s_bV";
export var icon = "s_bz";
export var outerFeaturesContainer = "s_bZ";
export var section = "s_j";
export var text = "s_bK";
export var title = "s_d";
export var topButton = "s_bY";
export var waveOne = "s_q";
export var waveThree = "s_bX";
export var waveTwo = "s_n";
export var waves = "s_bW";