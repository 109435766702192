// extracted by mini-css-extract-plugin
export var background = "bf_z";
export var backgroundContainer = "bf_d8";
export var bottomImage = "bf_t";
export var bottomImageContainer = "bf_fk";
export var buttons = "bf_bP";
export var container = "bf_h";
export var imagesContainer = "bf_fj";
export var middleWave = "bf_bQ";
export var section = "bf_j";
export var text = "bf_bK";
export var textContainer = "bf_bS";
export var title = "bf_d";
export var topImage = "bf_m";
export var waveOverlay = "bf_fh";