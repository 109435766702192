// extracted by mini-css-extract-plugin
export var container = "D_h";
export var content = "D_b5";
export var icon = "D_bz";
export var iconContainer = "D_cG";
export var icons = "D_cb";
export var image = "D_k";
export var label = "D_cH";
export var section = "D_j";
export var text = "D_bK";
export var title = "D_d";