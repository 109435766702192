// extracted by mini-css-extract-plugin
export var buttons = "C_bP";
export var column = "C_cy";
export var columns = "C_cx";
export var container = "C_h";
export var down = "C_cB";
export var download = "C_cz";
export var footer = "C_b7";
export var information = "C_cw";
export var section = "C_j";
export var show = "C_L";
export var text = "C_bK";
export var title = "C_d";
export var units = "C_cC";
export var up = "C_cF";
export var upload = "C_cD";
export var waveOne = "C_q";
export var waveThree = "C_bX";
export var waveTwo = "C_n";
export var waves = "C_bW";