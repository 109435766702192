// extracted by mini-css-extract-plugin
export var active = "R_bd";
export var arrow = "R_9";
export var button = "R_cc";
export var buttonMobile = "R_dL";
export var column = "R_cy";
export var columns = "R_cx";
export var container = "R_h";
export var description = "R_c6";
export var disclaimer = "R_cV";
export var disclaimerButton = "R_dG";
export var disclaimerContainer = "R_dN";
export var disclaimerText = "R_dR";
export var fadeBottom = "R_dQ";
export var fadeTop = "R_dP";
export var featureFooter = "R_dJ";
export var footer = "R_b7";
export var footerMobile = "R_dK";
export var heading = "R_G";
export var highlight = "R_dH";
export var icon = "R_bz";
export var iconContainer = "R_cG";
export var left = "R_ck";
export var padding = "R_dm";
export var popular = "R_dl";
export var prefix = "R_dF";
export var recommend = "R_dC";
export var recommended = "R_dD";
export var right = "R_cR";
export var section = "R_j";
export var show = "R_L";
export var subHeading = "R_H";
export var suffix = "R_dj";
export var title = "R_d";
export var waveContainer = "R_dM";
export var waveOne = "R_q";
export var waveThree = "R_bX";
export var waveTwo = "R_n";
export var waves = "R_bW";