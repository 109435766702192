// extracted by mini-css-extract-plugin
export var body = "bc_g";
export var buttons = "bc_bP";
export var container = "bc_h";
export var icon = "bc_bz";
export var icons = "bc_cb";
export var image = "bc_k";
export var items = "bc_fb";
export var label = "bc_cH";
export var mobileButtons = "bc_fc";
export var name = "bc_d9";
export var section = "bc_j";
export var selected = "bc_bg";
export var show = "bc_L";
export var title = "bc_d";