// extracted by mini-css-extract-plugin
export var background = "bw_z";
export var backgroundContainer = "bw_d8";
export var bottomWave = "bw_bR";
export var buttons = "bw_bP";
export var container = "bw_h";
export var content = "bw_b5";
export var icon = "bw_bz";
export var iconContainer = "bw_cG";
export var middleWave = "bw_bQ";
export var negativeTop = "bw_gN";
export var overlap = "bw_gQ";
export var section = "bw_j";
export var selected = "bw_bg";
export var tab = "bw_dx";
export var tabText = "bw_dw";
export var title = "bw_d";
export var topWave = "bw_gP";