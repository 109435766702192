// extracted by mini-css-extract-plugin
export var caption = "bs_gK";
export var grey = "bs_c5";
export var heading = "bs_G";
export var image = "bs_k";
export var left = "bs_ck";
export var reverse = "bs_c2";
export var right = "bs_cR";
export var section = "bs_j";
export var video = "bs_fD";
export var videoContainer = "bs_bG";
export var videoWrapper = "bs_bH";