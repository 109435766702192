// extracted by mini-css-extract-plugin
export var bottom = "bj_s";
export var contact = "bj_fn";
export var faqCat = "bj_fw";
export var faqCatDescription = "bj_fz";
export var faqCatTitle = "bj_fy";
export var faqCategories = "bj_fv";
export var faqIcon = "bj_fx";
export var footerText = "bj_bV";
export var icon = "bj_bz";
export var iconContainer = "bj_cG";
export var iconText = "bj_fq";
export var immediate = "bj_fp";
export var inputContainer = "bj_bx";
export var linksSubtitle = "bj_fs";
export var linksTitle = "bj_fr";
export var search = "bj_bw";
export var searchInput = "bj_by";
export var section = "bj_j";
export var selected = "bj_bg";
export var supportButton = "bj_fB";
export var supportLinks = "bj_ft";
export var top = "bj_l";
export var wave = "bj_fm";