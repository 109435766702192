// extracted by mini-css-extract-plugin
export var bottomCircle = "bq_gv";
export var content = "bq_b5";
export var description = "bq_c6";
export var hide = "bq_C";
export var icon = "bq_bz";
export var image = "bq_k";
export var imageContainer = "bq_gz";
export var left = "bq_ck";
export var nextYear = "bq_gy";
export var right = "bq_cR";
export var section = "bq_j";
export var shrink = "bq_gx";
export var title = "bq_d";
export var topCircle = "bq_gt";
export var wave = "bq_fm";
export var waveContainer = "bq_dM";
export var year = "bq_gw";