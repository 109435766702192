// extracted by mini-css-extract-plugin
export var bottomContainer = "q_bN";
export var buttons = "q_bP";
export var icon = "q_bz";
export var link = "q_bM";
export var section = "q_j";
export var social = "q_bL";
export var text = "q_bK";
export var title = "q_d";
export var topContainer = "q_bJ";
export var videoContainer = "q_bG";
export var videoWrapper = "q_bH";