// extracted by mini-css-extract-plugin
export var bottomWave = "bh_bR";
export var button = "bh_cc";
export var buttonContainer = "bh_N";
export var buttonFooter = "bh_fl";
export var container = "bh_h";
export var middleWave = "bh_bQ";
export var section = "bh_j";
export var subtitle = "bh_bT";
export var textContainer = "bh_bS";
export var title = "bh_d";